input,
select,
textarea,
button {
  font-family: inherit;
}
@font-face {
  font-family: 'Proxima Nova';
  font-weight: 700;
  font-display: swap;
  src: url('../../assets/fonts/proximanova-bold-webfont.woff2') format('woff2'),
    url('../../assets/fonts/proximanova-bold-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 500;
  font-display: swap;
  src: url('../../assets/fonts/proximanova-medium-webfont.woff2')
      format('woff2'),
    url('../../assets/fonts/proximanova-medium-webfont.woff') format('woff');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  font-display: swap;
  src: url('../../assets/fonts/proximanova-regular-webfont.woff2')
      format('woff2'),
    url('../../assets/fonts/proximanova-regular-webfont.woff') format('woff');
}
